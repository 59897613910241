(function() {
    var HIDDEN_FIELDSET_CLASS = 'form-block__fieldset--hidden';

    var memberForms = document.querySelectorAll('.js-member-form');

    function getSubmitButton(formContainer) {
        return formContainer.querySelector('.js-submit');
    }

    function handleResponse(form, responseText, errorCallback) {
        var response;
        try {
            response = JSON.parse(responseText);
        } catch (e) {
            response = {
                errors: ['Member signup internal server error']
            };
        }
        if (response.redirect) {
            location.href = response.redirect;
        } else {
            showError(response.errors, form);
            errorCallback();
        }
    }

    function handleSubmit(submitEvent) {
        submitEvent.preventDefault();
        var form = this;
        var submitButton = getSubmitButton(form);
        var originalSubmitText = submitButton.textContent;
        submitButton.textContent = submitButton.dataset.busyText;
        submitButton.disabled = true;
        var formData = new FormData(form);
        var xhr = new XMLHttpRequest();
        xhr.addEventListener('load', function() {
            handleResponse(form, this.responseText, function() {
                submitButton.textContent = originalSubmitText;
                submitButton.disabled = false;
            });
        });
        xhr.open(form.method, location.href);
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.send(formData);
    }

    function setupExtraFieldsSwitch(form) {
        var radio = form.querySelector('.js-member-extra-fields');
        var spouseFields = document.getElementById(radio.dataset.spouseFields);
        var selfFields = document.getElementById(radio.dataset.selfFields);
        var radioGroup = document.getElementById(radio.dataset.radioGroup);
        /* IE/Edge do not support 'input' event */
        radioGroup.addEventListener('change', function() {
            toggleExtraFields(radio, spouseFields, selfFields);
        });
    }

    function showError(errors, form) {
        console.warn(errors);
        var errorSummary = Strings.formValidationSummary;
        /* In case IE11 submits invalid date, due to missing support */
        for (var i = 0; i < errors.length; i++) {
            if (errors[i].indexOf('Birth Date') > -1) {
                errorSummary += Strings.formValidationSummaryDate;
                break;
            }
        }
        var errorContainer = form.querySelector('.js-message-placeholder');
        errorContainer.textContent = errorSummary;
        errorContainer.removeAttribute('hidden');
    }

    function toggleExtraFields(radio, spouseFields, selfFields) {
        var shouldShow = radio.checked;
        spouseFields.hidden = !shouldShow;
        spouseFields.disabled = !shouldShow;
        selfFields.classList[shouldShow?'remove':'add'](HIDDEN_FIELDSET_CLASS);
    }

    [].forEach.call(memberForms, function(form) {
        form.addEventListener('submit', handleSubmit);
        setupExtraFieldsSwitch(form);
    });
})();
