/* Set up each element with the given class - the trigger - so that activations
 * will toggle a related element - the target - on/off, e.g. to show and hide a
 * menu.
 *
 * For most elements, such activation would consist of a click event. However,
 * it is also possible to specify 'change' for radio buttons.
 *
 * The trigger must have a 'data-target' attribute containing the ID of the
 * target.
'*
 * Additionally, the trigger may have a 'data-hidetext' attribute containing
 * the text with which the trigger's contents should be replaced when the
 * target is showing. The trigger's initial text contents will be used when the
 * target is hidden. (Typically, the trigger would be a BUTTON element with
 * type=button.)
 *
 * If the trigger has the 'hidden' attribute, that attribute will be removed at
 * setup (so that the trigger doesn't need to show if the script isn't
 * functional, e.g. if JS is disabled).
 *
 * An element, referred to here as the "class target" element, will receive the
 * specified class when the target is showing. This element will be the one
 * whose ID is specified in the trigger's 'data-class-target' attribute. If no
 * such attribute exists, the "class target" will be the 'data-target'.
 *
 * Usage: Toggle.setup(selector, isDefaultVisible, activeClass, stuckWhenOpen,
 *                     triggerEvent, doScroll);
 *
 * where
 * selector: selector matching trigger element(s), e.g. '._js-menu-trigger'
 * isDefaultVisible: true if target should be visible by default, else false
 * activeClass: class name that the "class target" element (see above) should
 *              receive when the target is showing, e.g. 'is-expanded' (should
 *              not be present initially).
 * stuckWhenOpen: true if the trigger should only act to show the target, not
 *                to hide it again; false if it should be possible to
 *                repeatedly use it to alternatly show and hide
 * triggerEvent: name of event that should trigger the toggle, e.g. 'click'
 * doScrollWhenOpen: true if the trigger should also try to scroll the targeted
 *                   element into view when toggling it open, otherwise false
 */
var Toggle = (function() {
    function toggleClass(element, className, shouldHaveClass) {
        var classes;
        if (element.classList) {
            element.classList[shouldHaveClass ? 'add' : 'remove'](className);
        } else {
            if (shouldHaveClass) {
                element.className += ' ' + className;
            } else {
                classes = element.className.split(/\s+/);
                classes.splice(classes.indexOf(className));
                element.className = classes.join(' ');
            }
        }
    }

    function setState(trigger, target, shouldBeShowing, hideText, showText,
            activeClass, classTarget) {
        if (shouldBeShowing) {
            target.removeAttribute('hidden');
            toggleClass(classTarget, activeClass, true);
        } else {
            target.setAttribute('hidden', 'hidden');
            toggleClass(classTarget, activeClass, false);
        }
        target.setAttribute('aria-hidden',
                (shouldBeShowing ? 'false' : 'true'));
        trigger.setAttribute('aria-expanded',
                (shouldBeShowing ? 'true' : 'false'));
        trigger.textContent = (shouldBeShowing ? hideText : showText);
    }

    function setupToggle(selector, isDefaultVisible, activeClass,
            shouldStickInOpenState, activatingEvent, doScroll) {
        var triggers = document.querySelectorAll(selector);
        [].forEach.call(triggers, function(trigger) {
            var targetId = trigger.getAttribute('data-target');
            var target = document.getElementById(targetId);
            var targetIsShowing = isDefaultVisible;
            var showText = trigger.textContent.trim();
            var hideText = showText;
            var classTargetId = trigger.getAttribute('data-class-target');
            var classTarget = classTargetId ?
                    document.getElementById(classTargetId) : target;
            if (trigger.hasAttribute('data-hidetext')) {
                hideText = trigger.getAttribute('data-hidetext');
            }
            trigger.addEventListener(activatingEvent, function() {
                var targetWasShowing = !target.hasAttribute('hidden');
                targetIsShowing = !targetWasShowing || shouldStickInOpenState;
                setState(trigger, target, targetIsShowing, hideText, showText,
                        activeClass, classTarget);
                if (doScroll && targetIsShowing && target.scrollIntoView) {
                    target.scrollIntoView();
                }
            });
            trigger.setAttribute('aria-controls', targetId);
            trigger.removeAttribute('hidden');
            setState(trigger, target, targetIsShowing, hideText, showText,
                    activeClass, classTarget);
        });
    }

    var module = {};
    module.setup = setupToggle;
    return module;
})();

Toggle.setup('.js-peer-toggle', false, 'is-expanded', false, 'click');
Toggle.setup('.js-professional-toggle', false, 'is-expanded', false, 'click');
Toggle.setup('.js-help-toggle', false, 'is-expanded', false, 'click');
Toggle.setup('.js-accordion-toggle', false, 'is-expanded', false, 'click');
Toggle.setup('.js-member-form-trigger', false, 'button--hidden', true, 'click');
Toggle.setup('.js-donation-toggle', false, 'is-expanded', false, 'change');
Toggle.setup('.js-checkout-toggle', false, 'is-expanded', true, 'click', true);
Toggle.setup('.js-payment-toggle', true, 'is-expanded', false, 'change');
/* Menu should always show on wide screens. Ideally, the aria-hidden etc
 * attributes should probably reflect that, but the Toggle module as-is doesn't
 * really support this, calling it multiple times would cause multiple event
 * listeners to be added.
 */
Toggle.setup('.js-menu-toggle', false, 'is-expanded', false, 'click');
