/* Sets up custom validation errors for all specified form fields.
 * - The input field must be placed within a wrapper with class js-validate
 *   (only one input field per wrapper is supported).
 * - The wrapper will receive a 'form-block__field--error' class when invalid
 *   (this invalid state is normally only enabled on submit and only disabled
 *   on input).
 * - The input field must have an ID, and it must be possible to use it as a
 *   prefix for new IDs without risking collisions.
 * - When the field is invalid, a paragraph with class 'form-block__errors' is
 *   appended to the wrapper.
 * - The actual error messages for this paragraph are retrieved from the
 *   Strings global (which is a dependency). Instances of "{label}" in the
 *   strings are replaced by the label text of the appropriate field, converted
 *   to lowercase.
 *
 * If an input field of the type described above has a 'data-min' attribute,
 * it will be treated as invalid if its numerical value is less than the
 * 'data-min' attribute's value (assumed to be an integer).
 */
var ValidateField = (function() {
  ERROR_ID_SUFFIX = '-error';
  WRAPPER_ERROR_CLASS = 'form-block__field--error';
  ERROR_PARAGRAPH_CLASS = 'form-block__errors';
  ERROR_SUMMARY_SELECTOR = '.js-message-placeholder';
  LABEL_PLACEHOLDER = '{label}';

  function markAsInvalid(wrapper, input, labelText) {
    var paragraph;
    var errorId = input.id + ERROR_ID_SUFFIX;
    if (wrapper.classList.contains(WRAPPER_ERROR_CLASS)) {
      paragraph = document.getElementById(errorId);
    } else {
      paragraph = document.createElement('p');
      paragraph.id = errorId;
      paragraph.className = ERROR_PARAGRAPH_CLASS;
    }
    wrapper.classList.add(WRAPPER_ERROR_CLASS);
    var errorMessage = getErrorMessage(input, labelText);
    paragraph.textContent = errorMessage;
    wrapper.appendChild(paragraph);
    input.setAttribute('aria-describedby', errorId);
  }

  function markAsValid(wrapper, input) {
    if (!wrapper.classList.contains(WRAPPER_ERROR_CLASS)) {
      return;
    }
    input.removeAttribute('aria-describedby');
    var errorId = input.id + ERROR_ID_SUFFIX;
    var paragraph = document.getElementById(errorId);
    paragraph.parentNode.removeChild(paragraph);
    wrapper.classList.remove(WRAPPER_ERROR_CLASS);
  }

  function getErrorMessage(input, labelText) {
    var labelLower = labelText.toLowerCase();
    if (input.hasAttribute('data-min')) {
      return Strings.formValidationRepeatedSumTooLow;
    }
    if (input.validity.valid) {
      return '';
    }
    if (input.validity.valueMissing) {
      return Strings.formValidationMissing.replace('{label}', labelLower);
    }
    return Strings.formValidationInvalid.replace('{label}', labelLower);
  }

  /* Get label text to use for error messages in the given wrapper element.
   * Supports one of two things:
   * a) the wrapper is a regular div that contains one form control, as well as
   *    one <label> element which labels that control
   * b) the wrapper is a fieldset that contains a group of radio buttons, as
   *    well as legend which labels that radio group
   */
  function getLabelForWrapper(wrapper) {
      if (wrapper.tagName === 'FIELDSET') {
          return wrapper.querySelector('legend').textContent;
      }
      return wrapper.querySelector('label').textContent;
  }

  function showErrorSummary(form) {
    var errorContainer = form.querySelector(ERROR_SUMMARY_SELECTOR);
    if (!errorContainer.hidden) {
      return;
    }
    var errorParagraph = document.createElement('p');
    errorParagraph.textContent = Strings.formValidationSummary;
    errorParagraph.setAttribute('role', 'alert');
    errorContainer.appendChild(errorParagraph);
    errorContainer.hidden = false;
  }

  function validateCustomMinimum(input) {
    if (!input.hasAttribute('data-min')) {
      return true;
    }
    var customMinimum = parseInt(input.dataset.min);
    return input.value >= customMinimum;
  }

  function setup(selector) {
    var fieldWrappers = document.querySelectorAll(selector);
    [].forEach.call(fieldWrappers, setupWrapper);
  }

  function setupWrapper(fieldWrapper) {
    var allInputs = fieldWrapper.getElementsByTagName('input');
    var input = allInputs[0];
    var labelText = getLabelForWrapper(fieldWrapper);
    /* IE/Edge don't support input event on radio */
    var inputOrChangeEvent = input.type == 'radio' ? 'change' : 'input';
    input.addEventListener('invalid', function(e) {
      markAsInvalid(fieldWrapper, input, labelText);
      showErrorSummary(input.form);
      e.preventDefault();
    });
    if (allInputs.length > 1) {
        /* Supports radio buttons specifically */
        var preventDefault = function(e) { e.preventDefault(); };
        for (var i = 1; i < allInputs.length; i++) {
            allInputs[i].addEventListener('invalid', preventDefault);
        }
    }
    fieldWrapper.addEventListener(inputOrChangeEvent, function() {
      var customMinimumIsValid = validateCustomMinimum(input);
      if (input.validity.valid && customMinimumIsValid) {
        markAsValid(fieldWrapper, input);
      }
    });
    input.addEventListener('blur', function() {
      if (!validateCustomMinimum(input)) {
        markAsInvalid(fieldWrapper, input, labelText);
      }
    });
  }

  var module = {}
  module.setup = setup;
  module.setupWrapper = setupWrapper;
  return module;
})();

ValidateField.setup('.js-validate');
