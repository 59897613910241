/* Sets up an element so its position can be made "sticky" inside the viewport.
 * (Only one such element per page is supported.)
 *
 * Note: Behavior is different than that of the experimental CSS 'position'
 * value 'sticky'. In short, it's intended to be used for cases where the
 * "sticky" element might get too tall to fit in the available room.
 *
 * To use, set the following classes:
 * 'js-sticky': on the element which will be made sticky
 * 'js-sticky-placeholder': typically on the parent of the 'js-sticky' element;
 * this is intended to act as a "placeholder" to avoid having things jump
 * around when the sticky element gets taken out of the flow
 *
 * The "sticky" state is toggled (on window resize and scroll) based on the
 * position of 'js-sticky-placeholder' relative to the viewport, and on its
 * height relative to its parent's visible height (i.e. the part of the parent
 * box that is inside the viewport).
 * - Placeholder at least as tall as parent's *total* height: no stickyness
 * - Placeholder top edge is lower than the viewport top edge: no stickyness
 * - Else: Placeholder taller than parent's *visible* height: sticky to bottom
 * - Else: sticky to top
 *
 * When stickyness toggles on, the 'js-sticky' element will get a
 * 'is-sticky-to-top' or 'is-sticky-to-bottom' class, and the height of
 * 'js-sticky-placeholder' will be set equal to that of the 'js-sticky' element
 * (at that point in time) via the HTML 'style' attribute. When stickyness is
 * toggled off, the class and inline height styling will be removed.
 *
 * The actual fixed positioning in the sticky state is not done by this script;
 * it can be done in CSS triggering off the 'is-sticky' class (and potentially
 * other things such as media queries).
 */
(function() {
    if (!document.documentElement.classList) {
        return;
    }

    var STICKY_NONE = 0;
    var STICKY_TOP = 1;
    var STICKY_BOTTOM = 2;

    function init() {
        var stickyElement = document.querySelector('.js-sticky');
        var placeholder = document.querySelector('.js-sticky-placeholder');

        function updateSticky() {
            var stickyStatus = determineStickyStatus(placeholder);
            adjustHeight(stickyElement, placeholder,
                    stickyStatus !== STICKY_NONE);
            if (stickyStatus === STICKY_TOP) {
                stickyElement.classList.add('is-sticky-to-top');
                stickyElement.classList.remove('is-sticky-to-bottom');
            } else if (stickyStatus === STICKY_BOTTOM) {
                stickyElement.classList.remove('is-sticky-to-top');
                stickyElement.classList.add('is-sticky-to-bottom');
            } else {
                stickyElement.classList.remove('is-sticky-to-top');
                stickyElement.classList.remove('is-sticky-to-bottom');
            }
        }

        if (stickyElement && placeholder) {
            window.addEventListener('scroll', updateSticky);
            window.addEventListener('resize', updateSticky);
        }
    }

    function determineStickyStatus(element) {
        var boundingRect = element.getBoundingClientRect();
        var parentBoundingRect = element.parentNode.getBoundingClientRect();

        var elementHeight = boundingRect.height;
        var elementTopIsOutside = boundingRect.top < 0;
        var totalParentHeight = parentBoundingRect.height;
        var visibleParentHeight = totalParentHeight;
        if (parentBoundingRect.top < 0) {
            visibleParentHeight += parentBoundingRect.top;
        }

        if (elementHeight >= totalParentHeight) {
            return STICKY_NONE;
        }
        if (!elementTopIsOutside) {
            return STICKY_NONE;
        }
        if (elementHeight >= visibleParentHeight) {
            return STICKY_BOTTOM;
        }
        return STICKY_TOP;
    }

    function adjustHeight(sourceElement, targetElement, doSync) {
        var targetHeight = null;
        if (doSync) {
            var sourceBoundingRect = sourceElement.getBoundingClientRect();
            targetHeight = sourceBoundingRect.height + 'px';
        }
        targetElement.style.height = targetHeight;
    }

    init();
})();
