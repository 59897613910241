(function() {
    var paymentForms = document.querySelectorAll('._js-payment-form');

    function addCreditCardFieldFormatter(formContainer) {
        var field = formContainer.querySelector('._js-card-number');
        field.addEventListener('input', function() {
            var digits = field.value.replace(/\D/g, '');
            var valueWithSpaces = '';
            var i;
            for (i = 0; i < digits.length; i += 4) {
                if (valueWithSpaces.length > 0) {
                    valueWithSpaces += ' ';
                }
                valueWithSpaces += digits.substring(i, i + 4);
            }
            field.value = valueWithSpaces;
        });
    }

    function addExpiryFieldFormatter(formContainer) {
        var field = formContainer.querySelector('._js-card-date');
        var oldValue = field.value;
        field.addEventListener('input', function() {
            var newValue = field.value;
            var digits = newValue.replace(/\D/g, '');
            var valueWithDelimiter = digits;
            if (digits.length > 1) {
                if (newValue.length == 2 && oldValue === newValue + '/') {
                    /* Allow backspacing past the slash character. Downside of
                     * this logic: deleting the slash with e.g. the Delete key
                     * acts weird. Seems the alternative would be to catch
                     * keydown, or better/simpler: to split the field into two.
                     */
                    valueWithDelimiter = digits.substring(0, 1);
                } else {
                    valueWithDelimiter = digits.substring(0, 2) +
                            '/' + digits.substring(2, 4);
                }
            }
            field.value = valueWithDelimiter;
            oldValue = valueWithDelimiter;
        });
    }

    function getSubmitButton(formContainer) {
        return formContainer.querySelector('._js-submit');
    }

    function handleSubmit(clickEvent, paymentForm) {
        var payByCard = paymentForm.querySelector('._js-payment-card');
        var cardNumber = paymentForm.querySelector('._js-card-number').value;
        var cvc = paymentForm.querySelector('._js-card-cvc').value;
        var exp = paymentForm.querySelector('._js-card-date').value;
        var errors = [];
        if (payByCard.type !== 'hidden' && !payByCard.checked) {
            return;
        }
        clickEvent.preventDefault();
        setSubmitEnabled(false, paymentForm);
        Stripe.setPublishableKey(
                paymentForm.getAttribute('data-public-stripe-key'));
        cardErrorCodes = validateCardData(cardNumber, cvc, exp);
        validateCardData(cardNumber, cvc, exp).forEach(function(errorCode) {
            errors.push(paymentForm.getAttribute(errorCode));
        });
        if (errors.length > 0) {
            setDisplayedErrors(errors, paymentForm);
            setSubmitEnabled(true, paymentForm);
        } else {
            Stripe.card.createToken({
                number: cardNumber.replace(/ /g, ''),
                cvc: cvc,
                exp: exp
            }, function(statusCode, response) {
                var errorMessage;
                if (response.error) {
                    // Expiry year error can trigger here for e.g. "10/70"
                    if (response.error.code === 'invalid_expiry_year' ||
                            response.error.code === 'invalid_expiry_month') {
                        errorMessage =
                            paymentForm.getAttribute('data-error-expiration');
                    } else {
                        // Can any other errors happen here? We already called
                        // the Stripe.card.validate* methods, after all
                        errorMessage = response.error.message;
                    }
                    setDisplayedErrors([errorMessage], paymentForm);
                    setSubmitEnabled(true, paymentForm);
                } else {
                    paymentForm['token'].value = response.id;
                    triggerPayment(paymentForm);
                }
            });
        }
    }

    function validateCardData(cardNumber, cvc, expirationDate) {
        var errorCodes = [];
        if (!Stripe.card.validateCardNumber(cardNumber)) {
            errorCodes.push('data-error-card');
        }
        if (!Stripe.card.validateExpiry(expirationDate)) {
            errorCodes.push('data-error-expiration');
        }
        if (!Stripe.card.validateCVC(cvc)) {
            errorCodes.push('data-error-cvc');
        }
        return errorCodes;
    }

    function triggerPayment(paymentForm) {
        var xhr = new XMLHttpRequest();
        var fields = paymentForm.querySelectorAll('[name]');
        var formData = [];
        var handlerUrl = paymentForm.getAttribute('data-payment-action');
        [].forEach.call(fields, function(field) {
            if (field.type !== 'radio' || field.checked) {
                formData.push(field.name + '=' +
                        encodeURIComponent(field.value).replace(/%20/g, '+'));
            }
        });
        xhr.onload = function() {
            handlePaymentResult(xhr, paymentForm);
        };
        xhr.open('POST', handlerUrl);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.send(formData.join('&'));
    }

    function handlePaymentResult(xhr, paymentForm) {
        var responseObject;
        if (xhr.status == 200) {
            responseObject = JSON.parse(xhr.responseText);
            if (responseObject.error) {
                setDisplayedErrors(responseObject.error, paymentForm);
                setSubmitEnabled(true, paymentForm);
            } else {
                paymentForm.submit();
            }
        }
    }

    function setDisplayedErrors(errors, paymentForm) {
        var errorContainer = paymentForm.querySelector('._js-errors');
        var errorList;
        while (errorContainer.hasChildNodes()) {
            errorContainer.removeChild(errorContainer.firstChild);
        }
        if (errors.length > 0) {
            errorList = document.createElement('ul');
            errors.forEach(function(error) {
                var listItem = document.createElement('li');
                listItem.innerHTML = error;
                errorList.appendChild(listItem);
            });
            errorContainer.appendChild(errorList);
        }
    }

    function setSubmitEnabled(shouldBeEnabled, formContainer) {
        var submit = getSubmitButton(formContainer);
        var oldText = submit.textContent;
        submit.disabled = !shouldBeEnabled;
        submit.textContent = submit.getAttribute('data-alternate-text');
        submit.setAttribute('data-alternate-text', oldText);
    }

    [].forEach.call(paymentForms, function(paymentForm) {
        addCreditCardFieldFormatter(paymentForm);
        addExpiryFieldFormatter(paymentForm);
        getSubmitButton(paymentForm).addEventListener('click',
                function(clickEvent) {
                    handleSubmit(clickEvent, paymentForm);
                });
        paymentForm.removeAttribute('hidden');
    });
})();
