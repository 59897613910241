/* Custom functionality for the search page's search form.
 * Activates edit button and toggling between it and the search button
 */
(function() {
    var searchForm = document.querySelector('.js-search');
    if (!searchForm) {
        return;
    }
    var inputId = searchForm.getAttribute('data-input');
    var searchId = searchForm.getAttribute('data-search');
    var editId = searchForm.getAttribute('data-edit');
    var inputField = document.getElementById(inputId);
    var searchButton = document.getElementById(searchId);
    var editButton = document.getElementById(editId);

    // Edit/search toggle
    function toggleEditMode(edit) {
        var buttonToHide = edit ? editButton : searchButton;
        var buttonToShow = edit ? searchButton : editButton;
        buttonToHide.setAttribute('hidden', '');
        buttonToShow.removeAttribute('hidden');
    }

    editButton.addEventListener('click', function() {
        var inputLength = inputField.value.length;
        inputField.focus();
        if (inputField.setSelectionRange) {
            inputField.setSelectionRange(inputLength, inputLength);
        }
    });
    inputField.addEventListener('focus', function() {
        toggleEditMode(true);
    });
    editButton.setAttribute('aria-controls', inputId);
    toggleEditMode(false);
})();
