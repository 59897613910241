(function() {
    function handleClick() {
        if (!ed24Chat || !ed24Chat.publishAction) {
            return;
        }
        ed24Chat.publishAction({type: 'OPEN_CHAT', data: true});
    }

    var buttons = document.querySelectorAll('._js-chat-button');
    [].forEach.call(buttons, function(button) {
        button.addEventListener('click', handleClick);
    });
})();
